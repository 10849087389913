export function select(node: ParentNode, selector: string): Element {
  const result = node.querySelector(selector);
  if (!result) {
    throw new Error(`Did not find ${selector} on ${node.nodeName}`);
  }
  return result;
}

export function selectAll(
  node: ParentNode,
  selector: string,
): NodeListOf<Element> {
  const result = node.querySelectorAll(selector);
  if (!result) {
    throw new Error(`Did not find ${selector} on ${node.nodeName}`);
  }
  return result;
}

export function isElementInViewport(el: Element) {
  const { top, left, bottom, right } = el.getBoundingClientRect();

  return (
    top >= 0 &&
    left >= 0 &&
    bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}
