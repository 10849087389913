import HLS from "hls.js";
import { VidstackPlayer, VidstackPlayerLayout } from "vidstack/global/player";
import { isHLSProvider } from "vidstack";
import { isElementInViewport, select } from "./client/dom-utils.ts";

const player = await VidstackPlayer.create({
  target: "#my-player",
  title: "",
  load: "visible",
  muted: true,
  autoPlay: true,
  preferNativeHLS: true,
  layout: new VidstackPlayerLayout({}),
});

player.addEventListener("provider-change", (event) => {
  const provider = event.detail;
  if (isHLSProvider(provider)) {
    // Static import
    provider.library = HLS;
  }
});
async function getMedia() {
  let success = false;
  const disclaimer = select(document, "#privacy-note") as HTMLElement;
  const item = localStorage.getItem("deviceId") || undefined;

  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: { width: 1920, height: 1080, facingMode: "user", deviceId: item },
    });
    success = true;
    disclaimer.hidePopover();

    select(document, "#login").classList.add("tc-hidden");
    player.src = { src: stream, type: "video/object" };
    player.streamType = "live";
    showStream();
    const track = stream.getTracks()[0];
    if (track) {
      const deviceId = track.getSettings().deviceId;
      if (deviceId) {
        localStorage.setItem("deviceId", deviceId);
      }
    }
  } catch (err) {
    console.warn(err);
  }
  disclaimer.hidePopover();
  return success;
}

async function loggedOutInit() {
  const popoverButton = select(
    document,
    '[popovertarget="privacy-note"]',
  ) as HTMLElement;

  const videoVisible = select(document, "#video-screen").checkVisibility({
    checkVisibilityCSS: true,
    checkOpacity: true,
  });

  popoverButton.addEventListener("click", getMedia);
  let authed = false;

  try {
    const result = await navigator.permissions.query({ name: "camera" });
    if (result.state === "granted") {
      authed = true;
    } else if (result.state === "prompt") {
      console.warn("PROMPT");
    } else {
      console.warn("DENIED");
    }
    // Don't do anything if the permission was denied.
  } catch (e) {
    console.warn("Permissions querying not supported");
  }

  for (const device of await navigator.mediaDevices.enumerateDevices()) {
    console.warn(device);
    // if (authed) {
    //   break;
    // }
    // if (device.kind === "videoinput" && device.deviceId === item) {
    //   authed = true;
    // }
  }
  authed = authed && videoVisible && (await getMedia());
  if (!authed) {
    select(document, "#login").classList.add("!tc-opacity-100");
    select(document, "#login").classList.remove("tc-hidden");
  }
}

function showStream() {
  select(document, ".video-wrapper").classList.remove("tc-hidden");
  select(document, ".info-segment").classList.add("md:tc-flex");
  setTimeout(() => {
    requestAnimationFrame(() => {
      select(document, ".info-segment").classList.add("tc-opacity-100");
    });
  }, 200);
  select(document, ".video-wrapper").classList.remove("tc-w-full");
}

function leipertInit() {
  player.addEventListener("play", () => {
    const minutes = (new Date().getMinutes() + 19) % 60;
    const seconds = (new Date().getSeconds() + 44) % 60;
    const offset = minutes * 60 + seconds + 0.19;
    player.remoteControl.seeking(offset);
    player.remoteControl.seek(offset);
    player.streamType = "live:dvr";
  });
  player.addEventListener("playing", () => {
    showStream();
  });
  player.addEventListener("pause", () => {
    console.warn(player.currentTime); //43.19
  });
  player.src = {
    src: "https://vz-8378132f-140.b-cdn.net/58a7c308-8aee-42ad-a77f-8e5a1ae69a90/playlist.m3u8",
    type: "application/x-mpegurl",
  };

  showStream();
}

if (
  window.location.pathname === "/" ||
  window.location.pathname.startsWith("/leipert")
) {
  leipertInit();
} else {
  await loggedOutInit();
}
